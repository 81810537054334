import * as THREE from "three";
import { Store } from "./Store";

export const GRID_HELPER_NAME = "grid";

export class Helpers {
  store: Store;

  axesHelper: THREE.AxesHelper;

  grid: THREE.GridHelper;

  ground: THREE.Mesh;

  constructor(store: Store) {
    this.store = store;
    // @ts-ignore
    window.THREE = THREE;
    // @ts-ignore;
    window.store = store;
  }

  init() {
    this.setAxes();
    this.setGrid();
    this.setGround();
    this.addHelpers();
  }

  get gridHelper(): THREE.Object3D {
    return this.store.scene.getObjectByName(GRID_HELPER_NAME);
  }

  setAxes() {
    this.axesHelper = new THREE.AxesHelper(1000);
  }

  setGrid() {
    const gridColor = new THREE.Color("#888991");
    this.grid = new THREE.GridHelper(2000, 200, gridColor, gridColor);
    this.grid.position.setY(0.01);
    this.grid.name = GRID_HELPER_NAME;
  }

  addGround() {
    this.store.scene.add(this.ground);
  }

  removeGround() {
    this.ground.parent?.remove(this.ground);
  }

  addHelpers() {
    if (this.store.props.settings.showHelpers) {
      this.store.scene.add(this.axesHelper);
      this.store.scene.add(this.grid);
    }
  }

  removeHelpers() {
    if (!this.store.props.settings.showHelpers) {
      this.grid.parent?.remove(this.grid);
      this.axesHelper.parent?.remove(this.axesHelper);
    }
  }

  updateHelpers() {
    this.removeHelpers();
    this.addHelpers();
  }

  setGround() {
    this.ground = new THREE.Mesh(
      new THREE.PlaneBufferGeometry(100, 100),
      new THREE.MeshPhongMaterial({ color: "#ffffff", depthWrite: false })
    );
    this.ground.rotation.x = -Math.PI / 2;
    this.ground.receiveShadow = true;
  }
}
