import React from "react";
import {
  Grid,
  FormControlLabel,
  RadioGroup,
  FormLabel,
  FormControl,
  Radio,
  Typography,
  Checkbox,
} from "@material-ui/core";
import { BackgroundType, ModelsStoreSettings } from "Inrefaces";
import { useDispatch, useSelector } from "react-redux";
import { getModelsSettings, modelsUpdateSettings } from "store/models";

export const SettingsControl = () => {
  const dispatch = useDispatch();
  const { showHelpers, backgroundType } = useSelector(getModelsSettings);
  const onChange = (name: keyof ModelsStoreSettings) => (e, value) => {
    dispatch(modelsUpdateSettings({ [name]: value }));
  };

  return (
    <Grid container item>
      <Typography variant="h6">Settings</Typography>
      <FormControl component="fieldset" fullWidth>
        <FormLabel component="legend">Background</FormLabel>
        <RadioGroup
          aria-label="background_settings"
          name="background_settings"
          value={backgroundType}
          onChange={onChange("backgroundType")}
        >
          <FormControlLabel
            value={BackgroundType.pure}
            control={<Radio />}
            label="White"
          />
          <FormControlLabel
            value={BackgroundType.image}
            control={<Radio />}
            label="Background image"
          />
          <FormControlLabel
            value={BackgroundType.fog}
            control={<Radio />}
            label="Fog"
          />
        </RadioGroup>
      </FormControl>
      <FormControl component="fieldset" fullWidth>
        <FormLabel component="legend">Helpers</FormLabel>
        <FormControlLabel
          control={
            <Checkbox
              checked={showHelpers}
              onChange={onChange("showHelpers")}
              name="showHelpers"
            />
          }
          label="Show"
        />
      </FormControl>
    </Grid>
  );
};
