import * as R from "ramda";
import {
  AssetTypeItemSizeValue,
  AssetTypeItemValue,
  AssetTypeValue,
} from "Inrefaces";

export const formatAssetSizes = (sizesObj: Object) =>
  Object.entries(sizesObj).map(
    ([size, src]): AssetTypeItemSizeValue => ({
      size,
      src,
    })
  );

export const formatAssetItem = (itemObj: Object) =>
  Object.entries(itemObj).map(
    ([name, sizesObj]): AssetTypeItemValue => ({
      name,
      sizes: formatAssetSizes(sizesObj),
    })
  );

export const formatAssetTypes = (typesObj: Object) =>
  Object.entries(typesObj).map(
    ([typeName, colorsObj]): AssetTypeValue => ({
      name: typeName,
      colors: formatAssetItem(colorsObj),
    })
  );

export const loadModelDirFiles = (module) =>
  R.reduce((acc, fileName: string) => {
    const modulePath = module(fileName).default;
    const objPath = fileName
      .replaceAll("./", "")
      .replaceAll(".glb", "")
      .split("/");
    return R.set(R.lensPath(objPath), modulePath, acc);
  }, {});
