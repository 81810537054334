import { all, put, select, takeEvery } from "redux-saga/effects";
import {
  getAvatarSizesOptionsFromType,
  getModelOptionsColorsFromType,
  getModelOptionsColorsSizesFromType,
  getModels,
  getModelsGender,
  modelsResetBottomModel,
  modelsResetTopModel,
  modelsUpdateBottomModel,
  modelsUpdateGender,
  modelsUpdateTopModel,
} from "store/models";
import { AssetTypeName, GenderModelType, ModelsStoreType } from "Inrefaces";
import { setGenderType, setModelColor, setModelName } from "./models.actions";

function* setGenderTypeSaga(action: ReturnType<typeof setGenderType>) {
  const { payload: name } = action;
  const sizes = yield getAvatarSizesOptionsFromType(name);
  yield put(modelsUpdateGender({ name, size: sizes[0].size }));
  yield put(modelsResetTopModel());
  yield put(modelsResetBottomModel());
}

function* setModelNameSaga(action: ReturnType<typeof setModelName>) {
  const {
    payload: { assetType, name },
  } = action;
  const { name: gender }: GenderModelType = yield select(getModelsGender);
  const colors = yield getModelOptionsColorsFromType(gender, assetType, name);
  const color = colors[0].name;
  const sizes = yield getModelOptionsColorsSizesFromType(
    gender,
    assetType,
    name,
    color
  );
  const { size } = sizes[0];

  if (assetType === AssetTypeName.top) {
    yield put(modelsUpdateTopModel({ name, size, color }));
  } else {
    yield put(modelsUpdateBottomModel({ name, size, color }));
  }
}

function* setModelColorSaga(action: ReturnType<typeof setModelColor>) {
  const {
    payload: { assetType, color },
  } = action;
  const { name: gender }: GenderModelType = yield select(getModelsGender);
  const models: ModelsStoreType = yield select(getModels);

  const sizes = yield getModelOptionsColorsSizesFromType(
    gender,
    assetType,
    models[assetType].name,
    color
  );
  const { size } = sizes[0];

  if (assetType === AssetTypeName.top) {
    yield put(modelsUpdateTopModel({ size, color }));
  } else {
    yield put(modelsUpdateBottomModel({ size, color }));
  }
}

export function* modelsSagas() {
  yield all([yield takeEvery(setGenderType.getType(), setGenderTypeSaga)]);
  yield all([yield takeEvery(setModelName.getType(), setModelNameSaga)]);
  yield all([yield takeEvery(setModelColor.getType(), setModelColorSaga)]);
}
