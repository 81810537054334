export type StoreType = {
  models: ModelsStoreType;
};

export enum Gender {
  men = "men",
  women = "women",
}

export interface ModelType {
  name: string;
  color: string;
  size: string;
}

export interface GenderModelType extends ModelType {
  name: Gender;
}

export enum BackgroundType {
  image = "image",
  pure = "pure",
  fog = "fog",
}

export type ModelsStoreSettings = {
  showHelpers: boolean;
  backgroundType: BackgroundType;
};

export type ModelsLoadedSourceType = {
  src: string;
  file: File;
};

export type ModelsStoreType = {
  modelLoading: boolean;
  gender: GenderModelType;
  top: ModelType;
  bottom: ModelType;
  loadedSources: ModelsLoadedSourceType[];
  settings: ModelsStoreSettings;
};

export enum AssetTypeName {
  top = "top",
  bottom = "bottom",
}

export type AssetTypeItemSizeValue = {
  size: string;
  src: string;
};

export type AssetTypeItemValue = {
  name: string;
  sizes: AssetTypeItemSizeValue[];
};

export type AssetTypeValue = {
  name: string;
  colors: AssetTypeItemValue[];
};

export type AssetType = {
  [AssetTypeName.top]?: AssetTypeValue[];
  [AssetTypeName.bottom]?: AssetTypeValue[];
};
