import * as THREE from "three";
import * as R from "ramda";
import { GLTF, GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { Store } from "./Store";

export class ModelLoader {
  store: Store;

  gltfLoader = new GLTFLoader();

  //
  models = new THREE.Group();

  modelsBox: THREE.Box3;

  constructor(store: Store) {
    this.store = store;
    this.addModel = this.addModel.bind(this);
  }

  addModel(url: string): Promise<THREE.Group> {
    return new Promise((done) => {
      this.gltfLoader.load(
        url,
        (gltfModel: GLTF) => {
          const model = gltfModel.scene;
          model.name = url;
          this.models.add(model);
          done(model);
        },
        console.log
      );
    });
  }

  async addGltfModels(fileUrls: string[]) {
    this.store.props.setModelLoading(true);
    this.store.scene.add(this.models);

    await Promise.all(fileUrls.map(this.addModel));

    this.modelsBox = new THREE.Box3().setFromObject(this.models);

    this.updateMaterials();

    this.store.props.setModelLoading(false);
  }

  updateMaterials() {
    this.models.traverse((child) => {
      if (child instanceof THREE.Mesh) {
        child.castShadow = true;
        if (child.material instanceof THREE.MeshStandardMaterial) {
          child.material.envMapIntensity = 0.1;
          child.material.needsUpdate = true;
        } else {
          // eslint-disable-next-line no-restricted-syntax
          for (const material of child.material) {
            material.envMapIntensity = 0.1;
            material.needsUpdate = true;
          }
        }
      }
    });
  }

  cleanMaterial(material: THREE.Material) {
    material.dispose();

    Object.values(material).forEach((value) => {
      if (value instanceof THREE.Texture) {
        value.dispose();
      }
    });
  }

  disposeModels(urls: string[]) {
    urls.forEach((url) => {
      const obj = this.models.getObjectByName(url);
      if (!obj) return;
      obj.traverse((child) => {
        if (child instanceof THREE.Mesh) {
          child?.geometry?.dispose();

          if (child.material instanceof THREE.Material) {
            this.cleanMaterial(child.material);
          } else {
            // eslint-disable-next-line no-restricted-syntax
            for (const material of child.material) this.cleanMaterial(material);
          }
        }
      });
      setTimeout(() => {
        obj.parent.remove(obj);
      });
    });
  }

  async updateGltfModels(prevUrls: string[]) {
    const urlsToRemove = R.difference(prevUrls, this.store.props.fileUrls);
    const urlsToAdd = R.difference(this.store.props.fileUrls, prevUrls);

    this.disposeModels(urlsToRemove);
    await this.addGltfModels(urlsToAdd);
  }

  addBox() {
    const geometry = new THREE.BoxGeometry(3, 2, 1);
    const material = new THREE.MeshStandardMaterial({ color: 0x00ff00 });
    const cube = new THREE.Mesh(geometry, material);
    cube.castShadow = true;
    this.store.scene.add(cube);
  }
}
