import React from "react";
import * as R from "ramda";
import {
  Grid,
  InputLabel,
  Select,
  MenuItem,
  FormControl,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import {
  getAvatarSizesOptions,
  getModelIsLoading,
  getModelsGender,
  modelsUpdateGender,
} from "store/models";
import { avatars } from "assets";
import { createSelector } from "reselect";
import { formatAssetItem } from "assets/utils";
import { setGenderType } from "sagas/models";

const GENDER_CONTROL_LABEL_ID = "gender_select_label";
const GENDER_SIZE_CONTROL_LABEL_ID = "gender_size_select_label";

const getAvatarOptions = createSelector(formatAssetItem, R.identity);

export const GenderControl = () => {
  const dispatch = useDispatch();
  const { name, size } = useSelector(getModelsGender);
  const isLoading = useSelector(getModelIsLoading);

  const avatarOptions = getAvatarOptions(avatars);

  const avatarSizesOptions = useSelector(getAvatarSizesOptions);

  const updateGender = (e) => {
    dispatch(setGenderType(e.target.value));
  };

  const updateSize = (e) => {
    dispatch(modelsUpdateGender({ size: e.target.value }));
  };

  return (
    <Grid container item spacing={2}>
      <Grid item xs={8}>
        <FormControl fullWidth>
          <InputLabel id={GENDER_CONTROL_LABEL_ID}>gender</InputLabel>
          <Select
            fullWidth
            labelId={GENDER_CONTROL_LABEL_ID}
            onChange={updateGender}
            value={name ?? ""}
            disabled={!avatarOptions.length || isLoading}
          >
            {avatarOptions.map((option) => (
              <MenuItem key={option.name} value={option.name}>
                {option.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={4}>
        <FormControl fullWidth>
          <InputLabel id={GENDER_SIZE_CONTROL_LABEL_ID}>size</InputLabel>
          <Select
            labelId={GENDER_SIZE_CONTROL_LABEL_ID}
            onChange={updateSize}
            value={size ?? ""}
            disabled={!avatarSizesOptions.length || isLoading}
          >
            {avatarSizesOptions.map((option) => (
              <MenuItem value={option.size}>{option.size}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};
