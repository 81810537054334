import React, { ReactNode } from "react";
import { Provider } from "react-redux";
// eslint-disable-next-line import/no-extraneous-dependencies
import { composeWithDevTools } from "redux-devtools-extension";
import { applyMiddleware, compose, createStore } from "redux";
// eslint-disable-next-line import/no-extraneous-dependencies
import { createLogger } from "redux-logger";
import { loggers } from "redux-act";
import createSagaMiddleware from "redux-saga";
import { vroomReducer } from "store";
import { vroomSagas } from "sagas";

const logger = createLogger({
  ...loggers.reduxLogger,
});

const sagaMiddleware = createSagaMiddleware();

const composeEnhancers = compose(
  applyMiddleware(sagaMiddleware, logger),
  composeWithDevTools()
);

export const store = composeEnhancers(createStore)(vroomReducer);

sagaMiddleware.run(vroomSagas);

export const ReduxProvider = ({ children }: { children: ReactNode }) => {
  return <Provider store={store}>{children}</Provider>;
};
