import React, { Component, createRef } from "react";
import * as R from "ramda";
import { BackgroundType } from "Inrefaces";
import { delay } from "utils";
import {
  Animate,
  CameraAndControls,
  Helpers,
  Lights,
  ModelLoader,
  Settings,
  Store,
} from "./modules";
import { ThreeModuleProps } from "./ThreeInterface";

export class VirtualRoom extends Component<ThreeModuleProps, any> {
  canvasRef = createRef<HTMLDivElement>();

  store: Store;

  settings: Settings;

  constructor(props: ThreeModuleProps) {
    super(props);
    this.store = new Store(props);
    this.settings = new Settings(this.store);
  }

  async componentDidMount() {
    if (this.canvasRef.current) {
      this.store.ref = this.canvasRef.current;
    }
    await this.init();
  }

  componentWillUnmount() {
    this.settings.cleanUp();
    this.store.cameraAndControls.cleanUp();
    this.store.lights.cleanUp();
    this.store.animate.cleanUp();
  }

  componentDidUpdate(prevProps: ThreeModuleProps) {
    this.store.updateProps(this.props);
    if (!R.equals(prevProps.fileUrls, this.props.fileUrls)) {
      this.updateModels(prevProps);
    }
    if (
      !R.equals(prevProps.settings.showHelpers, this.props.settings.showHelpers)
    ) {
      this.store.helpers.updateHelpers();
    }
    if (
      !R.equals(
        prevProps.settings.backgroundType,
        this.props.settings.backgroundType
      )
    ) {
      this.updateBackground();
    }
  }

  async updateModels(prevProps: ThreeModuleProps) {
    await this.store.modelLoader.updateGltfModels(prevProps.fileUrls);
    await delay(1000);
    this.store.cameraAndControls.updateControlsTarget();
  }

  updateBackground() {
    const { backgroundType } = this.props.settings;
    if (backgroundType === BackgroundType.fog) {
      this.settings.setFogBackground();
      this.store.helpers.addGround();
    } else {
      this.store.helpers.removeGround();
      if (backgroundType === BackgroundType.image) {
        this.settings.setImageBackground();
      } else {
        this.settings.setPureBackground();
      }
    }
  }

  async init() {
    await this.settings.init();

    this.store.modelLoader = new ModelLoader(this.store);

    await this.store.modelLoader.addGltfModels(this.props.fileUrls);

    this.store.helpers = new Helpers(this.store);
    this.store.helpers.init();

    this.store.cameraAndControls = new CameraAndControls(this.store);
    this.store.cameraAndControls.setCamera();
    this.store.cameraAndControls.setControls();

    this.store.lights = new Lights(this.store);
    this.store.lights.setAmbientLight();
    this.store.lights.setDirectionalLights();

    this.settings.resize();

    this.store.animate = new Animate(this.store);
    this.store.animate.animate(0);

    this.afterInit();
  }

  afterInit() {
    this.updateBackground();
  }

  render() {
    return <div ref={this.canvasRef} className="canvas-wrapper" />;
  }
}
