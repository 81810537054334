import React from "react";
import { Grid, withStyles } from "@material-ui/core";
import { GenderControl } from "./Gender";
import { TopControl } from "./Top";
import { BottomControl } from "./Bottom";
import { SettingsControl } from "./Settings";
import { LoadFileControl } from "./LoadFile";

const ScrollableGrid = withStyles(() => ({
  root: {
    overflow: "scroll",
    height: "100%",
  },
}))(Grid);

export const VroomControls = () => {
  return (
    <ScrollableGrid container direction="column" alignItems="center" xs={4}>
      <Grid container direction="column" xs={12} spacing={4}>
        <GenderControl />
        <TopControl />
        <BottomControl />
        <LoadFileControl />
        <SettingsControl />
      </Grid>
    </ScrollableGrid>
  );
};
