import * as THREE from "three";
import TWEEN from "@tweenjs/tween.js";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { Store } from "./Store";

const DEFAULT_FOV = {
  rad: Math.PI / 4,
  get deg() {
    return this.rad * (180 / Math.PI);
  },
};

const animateCameraAndControls = (
  start: THREE.Vector3,
  target: THREE.Vector3,
  callback: (v: THREE.Vector3) => void
) => {
  return new TWEEN.Tween(start)
    .to(target, 1000)
    .easing(TWEEN.Easing.Cubic.InOut)
    .onUpdate(callback)
    .start();
};

export class CameraAndControls {
  camera: THREE.PerspectiveCamera;

  controls: OrbitControls;

  store: Store;

  constructor(store: Store) {
    this.store = store;
  }

  get cameraAspect(): number {
    return this.store.width / this.store.height;
  }

  setCamera() {
    this.camera = new THREE.PerspectiveCamera(
      DEFAULT_FOV.deg,
      this.cameraAspect,
      0.01,
      50
    );
    this.camera.up.set(0, 1, 0);
    const { y, z } = this.store.modelBoxSize;
    this.camera.position.set(0, y || 5, z * 15 || 15);
  }

  setControls() {
    this.controls = new OrbitControls(this.camera, this.store.ref);
    this.controls.target.copy(this.store.modelBoxCenter);
    this.controls.enableDamping = false;
    this.controls.maxDistance = 20;
    this.controls.enableKeys = true;
    this.controls.maxPolarAngle = Math.PI / 2;
    this.controls.zoomSpeed = 0.8;
    this.controls.rotateSpeed = 0.5;
    this.controls.update();
  }

  updateControlsTarget() {
    const { y, z } = this.store.modelBoxSize;

    animateCameraAndControls(
      this.controls.target,
      this.store.modelBoxCenter.clone(),
      (res: THREE.Vector3) => {
        this.controls.target.copy(res);
      }
    );

    animateCameraAndControls(
      this.camera.position,
      new THREE.Vector3(0, y, z * 15),
      (res: THREE.Vector3) => {
        this.controls.object.position.copy(res);
      }
    );
  }

  cleanUp() {
    if (this.controls) {
      this.controls.dispose();
    }
  }
}
