import React, { useState } from "react";
import * as R from "ramda";
import ReactResizeDetector from "react-resize-detector";
import { CircularProgress, Grid, withStyles } from "@material-ui/core";
import {
  getModelIsLoading,
  getModelsLoadedSources,
  getModelsSettings,
  getSelectedModelsSources,
  modelsSetModelLoading,
} from "store/models";
import { useDispatch, useSelector } from "react-redux";
import { VirtualRoom } from "./VirtualRoom";

const CircularProgressStyled = withStyles(() => ({
  root: {
    position: "absolute",
    left: "30px",
    top: "30px",
  },
}))(CircularProgress);

export const VirtualRoomWrapper = () => {
  const dispatch = useDispatch();
  const [size, setSize] = useState<{ width: number; height: number }>({
    width: 0,
    height: 0,
  });

  const selectedSourcesUrls = useSelector(getSelectedModelsSources);
  const loadedSources = useSelector(getModelsLoadedSources);
  const loadedSourcesUrls = R.map(R.prop("src"), loadedSources);
  const isLoading = useSelector(getModelIsLoading);
  const settings = useSelector(getModelsSettings);

  const fileUrls = [...selectedSourcesUrls, ...loadedSourcesUrls];

  const setModelLoading = (loading: boolean) => {
    dispatch(modelsSetModelLoading(loading));
  };

  const { width, height } = size;
  return (
    <Grid container xs={8}>
      <ReactResizeDetector
        handleWidth
        handleHeight
        onResize={(w, h) => setSize({ width: w, height: h })}
      />
      {isLoading && <CircularProgressStyled />}
      {width && height ? (
        <VirtualRoom
          settings={settings}
          setModelLoading={setModelLoading}
          fileUrls={fileUrls}
          width={width}
          height={height}
        />
      ) : null}
    </Grid>
  );
};
