import { loadModelDirFiles } from "assets/utils";

// @ts-ignore
const clothesModule = require.context("./clothes");

// @ts-ignore
const avatarsModule = require.context("./avatars");

const clothes = loadModelDirFiles(clothesModule)(clothesModule.keys());

const avatars = loadModelDirFiles(avatarsModule)(avatarsModule.keys());

export { clothes, avatars };
