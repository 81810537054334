import * as R from "ramda";
import { createSelector } from "reselect";
import { AssetTypeName, Gender, StoreType } from "Inrefaces";
import { avatars, clothes } from "assets";
import {
  formatAssetItem,
  formatAssetSizes,
  formatAssetTypes,
} from "assets/utils";

export const getModels = (state: StoreType) => state.models;

export const getModelIsLoading = createSelector(
  getModels,
  R.prop("modelLoading")
);

export const getModelsGender = createSelector(getModels, R.prop("gender"));

export const getModelsTopModel = createSelector(getModels, R.prop("top"));

export const getModelsBottomModel = createSelector(getModels, R.prop("bottom"));

export const getModelsSettings = createSelector(getModels, R.prop("settings"));

export const getModelsLoadedSources = createSelector(
  getModels,
  R.prop("loadedSources")
);

export const getAvatarSizesOptionsFromType = R.pipe(
  (type: Gender) => avatars[type] || {},
  formatAssetSizes
);

export const getAvatarSizesOptions = createSelector(
  getModelsGender,
  R.pipe(R.prop("name"), getAvatarSizesOptionsFromType)
);

export const getModelOptionsFromType = (
  gender: Gender,
  assetType: AssetTypeName
) => formatAssetTypes(clothes?.[gender]?.[assetType] ?? {});

export const getTopModelOptions = createSelector(getModelsGender, ({ name }) =>
  getModelOptionsFromType(name, AssetTypeName.top)
);

export const getBottomModelOptions = createSelector(
  getModelsGender,
  ({ name }) => getModelOptionsFromType(name, AssetTypeName.bottom)
);

export const getModelOptionsColorsFromType = (
  gender: Gender,
  assetType: AssetTypeName,
  name: string
) => formatAssetItem(clothes?.[gender]?.[assetType]?.[name] ?? {});

export const getTopModelColorOptions = createSelector(
  getModelsGender,
  getModelsTopModel,
  ({ name: gender }, { name }) =>
    getModelOptionsColorsFromType(gender, AssetTypeName.top, name)
);

export const getBottomModelColorOptions = createSelector(
  getModelsGender,
  getModelsBottomModel,
  ({ name: gender }, { name }) =>
    getModelOptionsColorsFromType(gender, AssetTypeName.bottom, name)
);

export const getModelOptionsColorsSizesFromType = (
  gender: Gender,
  assetType: AssetTypeName,
  name: string,
  color: string
) => formatAssetSizes(clothes?.[gender]?.[assetType]?.[name]?.[color] ?? {});

export const getTopModelColorSizesOptions = createSelector(
  getModelsGender,
  getModelsTopModel,
  ({ name: gender }, { name, color }) =>
    getModelOptionsColorsSizesFromType(gender, AssetTypeName.top, name, color)
);

export const getBottomModelColorSizesOptions = createSelector(
  getModelsGender,
  getModelsBottomModel,
  ({ name: gender }, { name, color }) =>
    getModelOptionsColorsSizesFromType(
      gender,
      AssetTypeName.bottom,
      name,
      color
    )
);

export const getSelectedModelsSources = createSelector(
  getModels,
  ({ gender, bottom, top }): string[] => {
    const arr = [];
    if (gender.name) {
      arr.push(avatars[gender.name][gender.size]);
    }
    if (bottom.name) {
      arr.push(
        clothes[gender.name][AssetTypeName.bottom][bottom.name][bottom.color][
          bottom.size
        ]
      );
    }
    if (top.name) {
      arr.push(
        clothes[gender.name][AssetTypeName.top][top.name][top.color][top.size]
      );
    }
    return arr;
  }
);
