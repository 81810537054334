import React, { SyntheticEvent, useRef } from "react";
import { Button, Grid, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import {
  getModelIsLoading,
  getModelsLoadedSources,
  modelsUpdateLoadedSources,
} from "store/models";
import { ModelsLoadedSourceType } from "Inrefaces";
import DeleteIcon from "@material-ui/icons/Delete";

export const LoadFileControl = () => {
  const dispatch = useDispatch();
  const inputRef = useRef<HTMLInputElement>();

  const loadedSources = useSelector(getModelsLoadedSources);
  const isLoading = useSelector(getModelIsLoading);

  const updateLoadedFiles = (sources: ModelsLoadedSourceType[]) => {
    dispatch(modelsUpdateLoadedSources(sources));
  };

  const uploadFile = (e: SyntheticEvent<HTMLInputElement>) => {
    const file = e.currentTarget.files[0];
    const src = URL.createObjectURL(file);
    const loadedFile: ModelsLoadedSourceType = {
      src,
      file,
    };
    updateLoadedFiles(loadedSources.concat(loadedFile));
    e.currentTarget.value = "";
  };

  const removeFile = (e: SyntheticEvent<HTMLButtonElement>) => {
    const copyLoadedFiles = loadedSources.concat();
    const removedLoadedFiles = copyLoadedFiles.splice(
      Number(e.currentTarget.value),
      1
    );
    URL.revokeObjectURL(removedLoadedFiles[0].src);
    updateLoadedFiles(copyLoadedFiles);
  };

  return (
    <Grid container item>
      <Grid container direction="column">
        <Typography variant="h6">Custom</Typography>
        <Grid item xs={12}>
          <Grid container direction="column" spacing={1}>
            {loadedSources.map(({ file, src }, index) => (
              <Grid key={src} item container alignItems="center" wrap="nowrap">
                <p>{file.name}</p>
                <Button
                  variant="contained"
                  size="small"
                  value={index}
                  disabled={isLoading}
                  onClick={removeFile}
                >
                  <DeleteIcon fontSize="small" />
                </Button>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <br />
        <Button
          variant="contained"
          color="primary"
          disabled={isLoading}
          onClick={() => inputRef?.current?.click()}
        >
          Upload file
        </Button>
        <input
          ref={inputRef}
          hidden
          type="file"
          accept="model/gltf-binary"
          onChange={uploadFile}
        />
      </Grid>
    </Grid>
  );
};
