import { createAction } from "redux-act";
import { AssetTypeName, Gender } from "Inrefaces";

export const setGenderType = createAction<Gender>("@saga/SET_GENDER_TYPE");

export const setModelName = createAction<{
  assetType: AssetTypeName;
  name: string;
}>("@saga/SET_MODEL_NAME");

export const setModelColor = createAction<{
  assetType: AssetTypeName;
  color: string;
}>("@saga/SET_MODEL_COLOR");
