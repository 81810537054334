import React from "react";
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import {
  getBottomModelColorOptions,
  getBottomModelColorSizesOptions,
  getBottomModelOptions,
  getModelIsLoading,
  getModelsBottomModel,
  modelsUpdateBottomModel,
} from "store/models";
import { setModelColor, setModelName } from "sagas/models";
import { AssetTypeName } from "Inrefaces";

const BOTTOM_CONTROL_MODEL_LABEL_ID = "bottom_control_model_label";
const BOTTOM_CONTROL_MODEL_COLOR_LABEL_ID = "bottom_control_model_color_label";
const BOTTOM_CONTROL_MODEL_COLOR_SIZE_LABEL_ID =
  "bottom_control_model_color_size_label";

export const BottomControl = () => {
  const dispatch = useDispatch();
  const { name, color, size } = useSelector(getModelsBottomModel);
  const isLoading = useSelector(getModelIsLoading);

  const modelOptions = useSelector(getBottomModelOptions);
  const modelColorOptions = useSelector(getBottomModelColorOptions);
  const modelColorSizesOptions = useSelector(getBottomModelColorSizesOptions);

  const updateModel = (e) => {
    dispatch(
      setModelName({ assetType: AssetTypeName.bottom, name: e.target.value })
    );
  };

  const updateModelColor = (e) => {
    dispatch(
      setModelColor({ assetType: AssetTypeName.bottom, color: e.target.value })
    );
  };

  const updateModelSize = (e) => {
    dispatch(modelsUpdateBottomModel({ size: e.target.value }));
  };

  return (
    <Grid container item>
      <Typography variant="h6">Bottom</Typography>
      <FormControl fullWidth>
        <InputLabel id={BOTTOM_CONTROL_MODEL_LABEL_ID}>model</InputLabel>
        <Select
          fullWidth
          labelId={BOTTOM_CONTROL_MODEL_LABEL_ID}
          onChange={updateModel}
          value={name ?? ""}
          disabled={!modelOptions.length || isLoading}
        >
          {modelOptions.map((option) => (
            <MenuItem key={option.name} value={option.name}>
              {option.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Grid container direction="row" spacing={2}>
        <Grid item xs={8}>
          <FormControl fullWidth>
            <InputLabel id={BOTTOM_CONTROL_MODEL_COLOR_LABEL_ID}>
              color
            </InputLabel>
            <Select
              fullWidth
              labelId={BOTTOM_CONTROL_MODEL_COLOR_LABEL_ID}
              onChange={updateModelColor}
              value={color ?? ""}
              disabled={!modelColorOptions.length || isLoading}
            >
              {modelColorOptions.map((option) => (
                <MenuItem key={option.name} value={option.name}>
                  {option.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={4}>
          <FormControl fullWidth>
            <InputLabel id={BOTTOM_CONTROL_MODEL_COLOR_SIZE_LABEL_ID}>
              size
            </InputLabel>
            <Select
              fullWidth
              labelId={BOTTOM_CONTROL_MODEL_COLOR_SIZE_LABEL_ID}
              onChange={updateModelSize}
              value={size ?? ""}
              disabled={!modelColorSizesOptions.length || isLoading}
            >
              {modelColorSizesOptions.map((option) => (
                <MenuItem key={option.size} value={option.size}>
                  {option.size}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
    </Grid>
  );
};
