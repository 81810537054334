import { createReducer } from "utils/customReduxAct";
import * as R from "ramda";
import { createAction } from "redux-act";
import {
  BackgroundType,
  ModelsLoadedSourceType,
  ModelsStoreSettings,
  ModelsStoreType,
} from "Inrefaces";

const defaultModelState = {
  name: null,
  color: null,
  size: null,
};

const defaultState = {
  modelLoading: false,
  gender: { ...defaultModelState },
  top: { ...defaultModelState },
  bottom: { ...defaultModelState },
  loadedSources: [],
  settings: {
    showHelpers: false,
    backgroundType: BackgroundType.fog,
  },
};

export const modelsSetModelLoading = createAction<boolean>(
  "MODELS_SET_MODEL_LOADING"
);

export const modelsUpdateGender = createAction<
  Partial<ModelsStoreType["gender"]>
>("MODELS_UPDATE_GENDER");

export const modelsUpdateTopModel = createAction<
  Partial<ModelsStoreType["top"]>
>("MODELS_UPDATE_TOP_MODEL");

export const modelsUpdateBottomModel = createAction<
  Partial<ModelsStoreType["bottom"]>
>("MODELS_UPDATE_BOTTOM_MODEL");

export const modelsResetTopModel = createAction<void>("MODELS_RESET_TOP_MODEL");

export const modelsResetBottomModel = createAction<void>(
  "MODELS_RESET_BOTTOM_MODEL"
);

export const modelsUpdateSettings = createAction<Partial<ModelsStoreSettings>>(
  "MODELS_UPDATE_SETTINGS"
);

export const modelsUpdateLoadedSources = createAction<ModelsLoadedSourceType[]>(
  "MODELS_UPDATE_LOADED_SOURCES"
);

export const modelsReducer = createReducer<ModelsStoreType>({}, defaultState);

modelsReducer
  .onc(modelsSetModelLoading, R.assoc("modelLoading"))
  .onc(modelsUpdateGender, (gender) =>
    R.over(R.lensProp("gender"), R.mergeLeft(gender))
  )
  .onc(modelsUpdateTopModel, (top) =>
    R.over(R.lensProp("top"), R.mergeLeft(top))
  )
  .onc(modelsUpdateBottomModel, (bottom) =>
    R.over(R.lensProp("bottom"), R.mergeLeft(bottom))
  )
  .onc(modelsResetTopModel, () => R.set(R.lensProp("top"), defaultModelState))
  .onc(modelsResetBottomModel, () =>
    R.set(R.lensProp("bottom"), defaultModelState)
  )
  .onc(modelsUpdateSettings, (settings) =>
    R.over(R.lensProp("settings"), R.mergeLeft(settings))
  )
  .onc(modelsUpdateLoadedSources, R.assoc("loadedSources"));
