import * as THREE from "three";
import { ThreeModuleProps } from "../ThreeInterface";
import { CameraAndControls } from "./CameraAndControls";
import { Lights } from "./Lights";
import { Animate } from "./Animate";
import { ModelLoader } from "./ModelLoader";
import { Helpers } from "./Helpers";

export class Store {
  ref: HTMLDivElement;

  props: ThreeModuleProps;

  scene: THREE.Scene;

  renderer: THREE.WebGLRenderer;

  cameraAndControls: CameraAndControls;

  lights: Lights;

  animate: Animate;

  modelLoader: ModelLoader;

  helpers: Helpers;

  constructor(props: ThreeModuleProps) {
    this.props = props;
  }

  get width() {
    return this.props.width;
  }

  get height() {
    return this.props.height;
  }

  get model(): THREE.Group {
    return this.modelLoader.models;
  }

  get modelBox(): THREE.Box3 {
    return this.modelLoader.modelsBox;
  }

  get modelBoxSize(): THREE.Vector3 {
    return this.modelBox.getSize(new THREE.Vector3());
  }

  get modelBoxCenter(): THREE.Vector3 {
    return this.modelBox.getCenter(new THREE.Vector3());
  }

  updateProps(props: ThreeModuleProps) {
    this.props = props;
  }
}
